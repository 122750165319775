// import Login from "../src/pages/Login";
// import Signup from "../src/pages/Signup";
// import PswForget from "../src/pages/PswForget";

import Layout6 from "../src/pages/Layout6/Layout6";


const routes = [
    // {path: "/password-forget", component: PswForget},
    // {path: "/sign-up", component: Signup},
    // {path: "/login", component: Login},

    {path: "/", component: Layout6},
];

export default routes;
